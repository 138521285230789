import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { BasePageData, getCosmicObjectWithApi } from '@finn/ui-cosmic';
import { config, useSessionInCookie } from '@finn/ui-utils';
import { useEffect, useState } from 'react';

import pageDataDe from '~/cosmic-cache/de-DE/retention-home.json';

const loadDevPage = true;
const pageDataObjectDe = pageDataDe?.objects?.[0];

export const useRetentionHomePageData = () => {
  const [devPageData, setDevPageData] = useState<any>(null);
  const session = useSessionInCookie();
  const isLoggedIn = session?.user?.email;
  const track = useTrackingStore((state) => state.track);

  useEffect(() => {
    if (config?.APP_STAGE === 'production' || !loadDevPage) {
      return;
    }
    getCosmicObjectWithApi({
      locale: 'de-DE',
      slug: 'retention-home-dev',
      showMetafields: true,
    }).then((data) => {
      if (!data) return;
      setDevPageData(data);
    });
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      track(TrackingEventName.LOGGED_IN_HOMEPAGE_VIEWED, {});
    }
  }, [isLoggedIn, track]);

  return {
    retentionPageData: isLoggedIn
      ? ((devPageData || pageDataObjectDe) as unknown as BasePageData)
      : null,
  };
};
